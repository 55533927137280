import {
  DistributionType,
  DropErrorType,
  isPurchaseDrop,
  pickGraphqlCode,
  useGetDropQuery,
} from '@collection-platform-frontend/api';
import { isAfter } from '@collection-platform-frontend/shared';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { DropAction, DropOperation } from './action';
import { DropGuard } from './guard';
import { DropHeader } from './header';
import { DropItem } from './item';

const debugAnimation = false;
const isDevelop = process.env.NODE_ENV === 'development';

type DropFormProps = {
  needAuth: boolean;
  applicationId: string;
  slug: string;
  primaryStyle?: string;
  initCode?: string;
  initOperation?: DropOperation[];
  acquireAuto?: boolean;
  onLogin?: () => void;
  onBack?: () => void;
  onNotFound?: () => void;
  onConfirm?: () => void;
  onReset?: () => void;
};

export const DropForm: FC<DropFormProps> = ({
  needAuth,
  applicationId,
  slug,
  primaryStyle,
  initCode,
  initOperation,
  acquireAuto,
  onLogin,
  onBack,
  onNotFound,
  onConfirm,
  onReset,
}) => {
  const [operation, setOperation] = useState<DropOperation[] | undefined>(
    initOperation,
  );

  const [{ fetching, data, error }] = useGetDropQuery({
    variables: {
      slug,
      applicationId,
    },
    pause: !slug,
    requestPolicy: 'cache-and-network',
  });
  const drop = data?.drop;
  const expired = drop?.expiredAt && isAfter(drop?.expiredAt);
  const isNotFoundDrop =
    pickGraphqlCode(error) === DropErrorType.NOT_FOUND_DROP;

  const minted = !!operation;
  const dropItems = operation;
  const distributionType = drop?.distributionType as DistributionType;

  const noStock = !initOperation && drop?.inventoryQuantity === 0;
  const soldOut = isPurchaseDrop(distributionType) && noStock;

  const currentDrop = useMemo(() => {
    return drop
      ? [
          {
            image: drop.thumbnailAssetUrl,
            name: drop.name,
          },
        ]
      : undefined;
  }, [drop]);

  const initCodes = useMemo(() => {
    return initCode ? initCode.split(',') : undefined;
  }, [initCode]);

  useEffect(() => {
    if (initOperation) {
      setOperation(initOperation);
    }
  }, [initOperation]);

  useEffect(() => {
    if (isNotFoundDrop) {
      onNotFound && onNotFound();
    }
  }, [isNotFoundDrop, onNotFound]);

  const onSubmit = useCallback(
    (operation?: DropOperation[]) => {
      setOperation(operation ?? currentDrop);
    },
    [currentDrop],
  );

  if (fetching || !drop) {
    return null;
  }

  return (
    <div className="flex flex-col h-full px-6 space-y-6">
      <DropHeader
        minted={minted}
        expired={expired}
        soldOut={soldOut}
        distributionType={distributionType}
      />
      <DropItem
        minted={minted}
        drop={currentDrop?.[0]}
        dropItems={dropItems}
        dropOperation={operation}
        initCode={initCodes}
      />
      <DropGuard
        expired={expired}
        soldOut={soldOut}
        needAuth={needAuth}
        primaryStyle={primaryStyle}
        onBack={onBack}
        onLogin={onLogin}
      >
        <DropAction
          debug={debugAnimation && isDevelop}
          minted={minted}
          initCode={initCodes}
          applicationId={applicationId}
          dropId={drop.id}
          distributionType={distributionType}
          primaryStyle={primaryStyle}
          price={drop.price}
          description={drop.description}
          notice={drop.notice}
          inventoryQuantity={drop.inventoryQuantity}
          acquisitionLimitCount={drop.acquisitionLimitCount}
          totalQuantity={drop.totalQuantity}
          acquireAuto={acquireAuto}
          onSubmit={onSubmit}
          onReset={onReset}
          onConfirm={onConfirm}
        />
      </DropGuard>
    </div>
  );
};
