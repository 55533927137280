import {
  campaignErrorMessage,
  dropErrorMessage,
  DropItemData,
  ExchangeItemData,
  pickGraphqlCode,
  useAssignExchangeableItemMutation,
} from '@collection-platform-frontend/api';
import { cn } from '@collection-platform-frontend/shared';
import { Button, Image, Typography } from '@collection-platform-frontend/ui';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { PointColumn } from '../point-column';
import BaseModal from './base';

type Props = {
  show: boolean;
  applicationId: string;
  pointUnit: string;
  customerPointBalance?: number;
  customerExchangedPoint?: number;
  exchangeItem?: ExchangeItemData;
  onExchange: (data: ExchangeItemData, dropItem: DropItemData) => void;
  onClose: () => void;
};

export const ExchangeItemModal: FC<Props> = ({
  show,
  applicationId,
  pointUnit,
  customerPointBalance,
  customerExchangedPoint,
  exchangeItem,
  onExchange,
  onClose,
}) => {
  const balance = customerPointBalance
    ? customerPointBalance - (customerExchangedPoint ?? 0)
    : 0;

  const isCanExchange = balance >= (exchangeItem?.achievementPoint ?? 0);
  const afterBalance = balance - (exchangeItem?.achievementPoint ?? 0);
  const dropItems = useMemo(
    () => exchangeItem?.dropItems ?? [],
    [exchangeItem],
  );

  const [errorMessage, setErrorMessage] = useState<string>();

  const [{ fetching }, exchangeItemMutation] =
    useAssignExchangeableItemMutation();

  useEffect(() => {
    if (!show) {
      setErrorMessage(undefined);
    }
  }, [show]);

  const onExchangeItem = useCallback(async () => {
    if (!exchangeItem) {
      return;
    }

    const { error, data } = await exchangeItemMutation({
      applicationId,
      campaignExchangeableItemId: exchangeItem.id,
    });
    const itemId = data?.assignExchangeableItemToCustomer.itemId;
    if (itemId) {
      const dropItem = dropItems.find(
        ({ id }) => id === itemId,
      ) as DropItemData;
      onExchange && onExchange(exchangeItem, dropItem);
    }

    const errorCode = pickGraphqlCode(error);
    if (errorCode) {
      setErrorMessage(
        campaignErrorMessage[errorCode] ??
          dropErrorMessage[errorCode] ??
          errorCode,
      );
    }
  }, [
    dropItems,
    exchangeItemMutation,
    onExchange,
    exchangeItem,
    applicationId,
  ]);

  if (!exchangeItem) {
    return null;
  }

  return (
    <BaseModal show={show} onClose={onClose}>
      <div className="mb-6">
        <Typography variant="h2" className="mb-2">
          ポイントと交換しますか？
        </Typography>
        <Typography variant="body2" className="text-wallet-light-secondary">
          交換可能なポイントを消費してアイテムを交換できます。
        </Typography>
      </div>
      <div className="flex flex-col items-center mb-6 space-y-2">
        {exchangeItem.bannerImageAsset?.url && (
          <Image
            className="h-[254px] w-[254px] rounded-3xl overflow-hidden"
            objectFit="cover"
            layout="fill"
            src={exchangeItem.bannerImageAsset?.url}
            alt="exchange_item_image"
          />
        )}
        <Typography variant="h3">{exchangeItem.title}</Typography>
        <div className="flex flex-row items-center justify-center gap-x-1">
          <Typography variant="caption" className="text-wallet-light-secondary">
            必要ポイント：
          </Typography>
          <PointColumn
            amount={exchangeItem.achievementPoint}
            unit={pointUnit}
          />
        </div>
      </div>
      <div className="w-full px-2 py-2 mb-6 bg-wallet-primary rounded-3xl dark:bg-wallet-inactive">
        <div className="flex flex-col px-2 space-y-2 divide-y">
          <div className="flex items-center justify-between w-full py-2">
            <Typography variant="caption" className="text-wallet-secondary">
              交換可能ポイント
            </Typography>
            <Typography variant="caption" className="text-wallet-secondary">
              {`${balance} ${pointUnit}`}
            </Typography>
          </div>
          <div className="flex items-center justify-between w-full py-2">
            <Typography variant="h4">ポイント利用</Typography>
            <Typography variant="h4">
              <span
                className={cn('text-wallet-secondary', {
                  'text-wallet-error': !isCanExchange,
                })}
              >
                {balance}
              </span>
              {` → ${
                isCanExchange
                  ? `${afterBalance} ${pointUnit}`
                  : `${Math.abs(afterBalance)} ${pointUnit}不足`
              }`}
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center mb-4 space-y-1">
        <Button
          primary
          className="w-[200px]"
          disabled={!isCanExchange}
          loading={fetching}
          onClick={onExchangeItem}
        >
          {isCanExchange ? '交換する' : 'ポイント不足'}
        </Button>
        {errorMessage && (
          <Typography
            variant="caption"
            className="pt-1 text-wallet-light-error dark:text-wallet-error"
          >
            {errorMessage}
          </Typography>
        )}
      </div>
      {/* drop画像を利用する場合のpreloader */}
      {/* <div className="hidden w-[200px] h-[200px]">
        {dropItems.map((dropItem, i) => {
          return (
            <Image
              key={i}
              className="w-full h-full"
              src={dropItem.thumbnailAssetUrl}
              alt="thumbnail_drop_item"
              layout="fill"
            />
          );
        })}
      </div> */}
    </BaseModal>
  );
};
