import {
  campaignErrorMessage,
  CampaignErrorType,
  CampaignMissionType,
  DropItemData,
  ExchangeItemData,
  getMissionStartUrl,
  hasQuestLandingPageBy,
  pickGraphqlCode,
  useMissionPageQuery,
  useRecordMissionAchievement,
  useRecordMissionAchievementByMissionId,
  useWatchBadgeAwardEvent,
} from '@collection-platform-frontend/api';
import { format } from '@collection-platform-frontend/shared';
import {
  Accordion,
  Button,
  Container,
  Image,
  TabNavigator,
  Typography,
  useToast,
} from '@collection-platform-frontend/ui';
import { FC, useCallback, useEffect, useState } from 'react';

import { BadgeCard } from './badge-card';
import { ButtonArea } from './button-area';
import { ExchangeItemCard } from './exchange-item-card';
import { CompleteIcon } from './icons';
import { MissionItem } from './mission-item';
import {
  AchievePointByCodeModal,
  AchievePointByCodeState,
  BadgeAwardModal,
  BadgeAwardModalState,
  ExchangeItemModal,
  ExchangeItemModalState,
  ExchangePointToItemModal,
  ExchangePointToItemModalState,
  LoginModal,
  MissionAchievedModalState,
  MissionAchievementModal,
  ModalStateType,
  useModal,
} from './modals';
import { QuizModals } from './quiz-modals';
import { UserBadges } from './user-badges';

const campaignNotice = `
### ＜注意事項＞
- ・本キャンペーンは、Anique株式会社（以下、当社）が運営しています
- ・本キャンペーンへの参加には、Anique Walletへのご登録が必要です
- ・本キャンペーンへの参加、特典アイテムの閲覧にかかる通信費はお客様のご負担となります
- ・本キャンペーンは、予告なく中止または変更する場合があります
- ・当社における個人情報の取扱いは、当社[プライバシーポリシー](/policy)に従います
- ・本キャンペーン中に発生したトラブル等につきましては、当社では一切の責任を負いかねます
- ・不正が確認された場合、特典を受け取る権利は無効となります
- ・特典の権利譲渡はできません
- ・メールアドレスでAnique Walletの会員情報を複数所有することは利用規約に反する行為となります。事務局で複数の会員登録をしていると判断した場合、当選結果を無効とさせていただく場合がございます
</br>
</br>

### ＜クエストキャンペーンについて＞
- ・開催期間はキャンペーンTOPページ上で記載の日時となります（特典交換期間とは異なります）
- ・「クエストキャンペーン」は、キャンペーンの指定する期間において、複数のミッションをクリアしてためたポイントをサイト上で交換することで特典（デジタルアイテム）を入手できるキャンペーンです
- ・本キャンペーンで取得できるポイントはキャンペーンの達成判定のみに用いられる中間ポイントで、金銭的な価値はありません
- ・本キャンペーンで取得したポイントは、クエストキャンペーンの開催期間及び特典交換期間のみで有効です
</br>
</br>

### ＜ミッションについて＞
- ・ミッションは、ミッションページで提示される各アクションの実行でクリアが判定されます
- ・各ミッションのクリアが判定されるとミッションページにて「達成済み」の表示が入ります。不具合により表示が変わらない場合は、お手数ですが再度お試しください
- ・各ミッションの達成が記録されると同時に、ミッションごとに指定されたポイント数が加算されます
- ・クイズミッションは正解するまで何度でも挑戦可能です
</br>
</br>

### ＜特典獲得（アイテム交換）について＞
- ・本キャンペーンの特典のデジタルアイテムは、交換ページ上でポイントと交換することで入手可能です
- ・特典の内容・交換に必要なポイント数・交換期間は交換ページ上で確認できます
- ・一度獲得したデジタルアイテムは、Anique Walletよりいつでも閲覧可能です
- ・引き換え期間をすぎてしまうと特典を獲得する権利は失効となります
</br>
</br>

### ＜本キャンペーンのデジタルアイテムについて＞
- ・本キャンペーンで取得できるデジタルアイテムに付随するシリアルナンバーは、発行上限数の範囲において、デジタルアイテムを配布する際にランダムに設定されます
- ・本アイテムに関する創作物にかかる著作権、その他の知的財産権の一切は著者ならびに著者より委託を受けた第三者に帰属します。本商品を購入した方は、私的利用ならびに本商品の提供者が定める範囲を超えて、商用利用、その他の複製、転載、改変を行うことはできません
- ・アカウントが削除された場合、本デジタルアイテムの利用権は消失し、アカウントを含め復旧はできません
- ・本サービスあるいは本デジタルアイテムの取りあつかいが終了した場合、本デジタルアイテムの利用権は消失します。上記の他、本サービスの利用規約その他の条件が適用されます
</br>
</br>

### ＜お困りのときは＞
- ・ミッションのクリアが判定されない
  - サイトのメンテナンスやサバーダウンなどで一時的にサービスが停止され、ミッションが未達となる場合があります。お手数ですが、時間をおいて再度お試しください
</br>
</br>

- ・ポイントをためたのにアイテムがもらえなかった
  - 申し訳ございませんが、数量限定の特典の場合、先着順のためタイミングによっては受け取ることができません
</br>
</br>

- ・有効期限内にアイテムを交換できなかった
  - 申し訳ございませんが、権利は失効となります
</br>
</br>
`;

// NOTE: ミッションを開始したタイミングで、ミッション達成を記録するタイプ
const ACHIEVEMENT_PRE_RECORDED_MISSION_TYPES: CampaignMissionType[] = [
  CampaignMissionType.TwitterFollow,
  CampaignMissionType.TwitterTweet,
  CampaignMissionType.TwitterRetweet,
  CampaignMissionType.GeneralOpenExternalUrl,
];

export enum CampaignTabEnum {
  mission = 0,
  badge = 1,
  exchangeable = 2,
  exchanged = 3,
}

export type CampaignTab = keyof typeof CampaignTabEnum;

type Props = {
  slug: string;
  initTab?: CampaignTab; // NOTE: URLクエリで指定されたタブを開く
  initAchievementCode?: string; // NOTE: URLクエリで指定されたミッション達成コードを記録する
  hasCustomer: boolean;
  applicationId: string;
  onCollection: () => void;
  onNotFound: () => void;
  onOpenInNewTab: (url: string) => void;
  onSignIn: () => void;
};

export const MissionPageContent: FC<Props> = ({
  slug,
  initTab,
  initAchievementCode,
  hasCustomer,
  applicationId,
  onCollection,
  onOpenInNewTab,
  onNotFound,
  onSignIn,
}) => {
  const { failure } = useToast();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [tabIndex, setTabIndex] = useState<number>(
    initTab ? CampaignTabEnum[initTab] : 0,
  );

  const {
    error: queryError,
    campaign,
    missions,
    achievementIcons,
    exchangeableItems,
    exchangedItems,
    customerPointBalance,
    customerExchangedPoint,
    isExpiredCampaign,
    isNotFoundCampaign,
    refetch: refetchMissionPage,
    hasAchievementIcons,
    hasExchangeableItems,
    refetchCampaignExchangeItems,
  } = useMissionPageQuery(hasCustomer, slug, applicationId);

  const {
    state: modalState,
    closeModal,
    openMissionAchievementModal,
    openBadgeAwardModal,
    openLoginModal,
    openQuizChallengeModal,
    openQuizModal,
    openExchangeItemModal,
    openExchangePointToItemModal,
    openAchievePointByCodeModal,
  } = useModal(hasCustomer);

  const [, recordMissionAchievementByAchievementCode] =
    useRecordMissionAchievement(applicationId);
  const [, recordMissionAchievementByMissionId] =
    useRecordMissionAchievementByMissionId(applicationId);

  const recordMissionAchievementByAchievementCodeAndOpenModal = useCallback(
    async (
      campaignId: string,
      achievementCode: string,
      closePreviousModal?: boolean,
    ) => {
      const state = await recordMissionAchievementByAchievementCode(
        campaignId,
        achievementCode,
      );

      const { data, error } = state;
      const isSuccess = !error && data;

      if (isSuccess) {
        if (closePreviousModal) closeModal();

        const missionId = data.achieveCampaignMission.campaignMissionId;
        const achievementPoint = data.achieveCampaignMission.achievementPoint;
        const mission = missions?.find(({ id }) => id === missionId);
        if (mission) {
          openMissionAchievementModal({
            ...mission,
            achievementPoint,
          });
        }
      }

      return state;
    },
    [
      closeModal,
      missions,
      openMissionAchievementModal,
      recordMissionAchievementByAchievementCode,
    ],
  );

  const recordMissionAchievementByMissionIdAndOpenModal = useCallback(
    async (missionId: string, closePreviousModal?: boolean) => {
      const state = await recordMissionAchievementByMissionId(missionId);

      const { data, error } = state;
      const isSuccess = !error && data;

      if (isSuccess) {
        if (closePreviousModal) closeModal();

        const missionId =
          data.achieveCampaignMissionByMissionId.campaignMissionId;
        const achievementPoint =
          data.achieveCampaignMissionByMissionId.achievementPoint;

        const mission = missions?.find(({ id }) => id === missionId);
        if (mission) {
          openMissionAchievementModal({
            ...mission,
            achievementPoint,
          });
        }
      }

      return state;
    },
    [
      missions,
      recordMissionAchievementByMissionId,
      closeModal,
      openMissionAchievementModal,
    ],
  );

  const recordMissionCodeByMissionIdAndOpenModal = useCallback(
    async (
      missionId: string,
      achievementPoint: number,
      closePreviousModal?: boolean,
    ) => {
      if (closePreviousModal) closeModal();

      const mission = missions?.find(({ id }) => id === missionId);
      if (mission) {
        openMissionAchievementModal({
          ...mission,
          achievementPoint,
        });
      }
    },
    [
      missions,
      recordMissionAchievementByMissionId,
      closeModal,
      openMissionAchievementModal,
    ],
  );

  const onMissionStart = useCallback(
    async (targetId: string) => {
      if (!campaign?.id) return;

      const mission = missions?.find((mission) => mission.id === targetId);
      if (!mission || mission.achievedAt) return;

      if (!hasCustomer) {
        openLoginModal();
        return;
      }

      const url = getMissionStartUrl(mission);
      if (
        mission.type &&
        ACHIEVEMENT_PRE_RECORDED_MISSION_TYPES.includes(mission.type)
      ) {
        const { data, error } =
          await recordMissionAchievementByMissionIdAndOpenModal(mission.id);

        if (error) {
          const errorCode = pickGraphqlCode(error);
          if (errorCode) {
            setErrorMessage(campaignErrorMessage[errorCode] ?? errorCode);
          }
          return;
        }

        if (data) {
          onOpenInNewTab(url.href);
        }
      } else if (mission.type === CampaignMissionType.GeneralQuiz) {
        openQuizChallengeModal({
          mission: {
            id: mission.id,
          },
        });
      } else if (mission.type === CampaignMissionType.Code) {
        if (!hasCustomer) {
          openLoginModal();
          return;
        }

        openAchievePointByCodeModal(mission as any);
      } else {
        onOpenInNewTab(url.href);
      }
    },
    [
      campaign?.id,
      missions,
      hasCustomer,
      openLoginModal,
      recordMissionAchievementByMissionIdAndOpenModal,
      onOpenInNewTab,
      openQuizChallengeModal,
      openAchievePointByCodeModal,
    ],
  );

  const onOpenQuizModal = useCallback(
    (achievementPointName: string) => (missionId: string, quizId: string) => {
      closeModal();
      openQuizModal({
        mission: {
          id: missionId,
        },
        achievementPointName: achievementPointName,
        quizId,
      });
    },
    [closeModal, openQuizModal],
  );

  const onAchieveQuizMission = useCallback(
    (missionId: string) => {
      recordMissionAchievementByMissionIdAndOpenModal(missionId, true).then(
        () => {
          refetchMissionPage();
        },
      );
    },
    [recordMissionAchievementByMissionIdAndOpenModal, refetchMissionPage],
  );

  const onAchieveCodeMission = useCallback(
    (missionId: string, point: number) => {
      recordMissionCodeByMissionIdAndOpenModal(missionId, point, true).then(
        () => {
          refetchMissionPage();
        },
      );
    },
    [recordMissionCodeByMissionIdAndOpenModal, refetchMissionPage],
  );

  const onExchangeAction = useCallback(
    (item: ExchangeItemData) => () => {
      if (!hasCustomer) {
        openLoginModal();
        return;
      }

      openExchangeItemModal(item);
    },
    [hasCustomer, openLoginModal, openExchangeItemModal],
  );

  const onExchange = useCallback(
    async (item: ExchangeItemData, dropItem: DropItemData) => {
      closeModal();

      setTimeout(() => {
        openExchangePointToItemModal(item, dropItem);
      }, 100);
    },
    [closeModal, openExchangePointToItemModal],
  );

  const onExchangeComplete = useCallback(async () => {
    refetchCampaignExchangeItems(hasCustomer);
    closeModal();
  }, [refetchCampaignExchangeItems, closeModal, hasCustomer]);

  useWatchBadgeAwardEvent({
    pointBalance: customerPointBalance,
    achievementIcons: achievementIcons,
    onEvent: openBadgeAwardModal,
  });

  useEffect(() => {
    if (initTab && CampaignTabEnum[initTab]) {
      setTabIndex(CampaignTabEnum[initTab]);
    }
  }, [initTab]);

  useEffect(() => {
    if (isNotFoundCampaign) {
      onNotFound();
      return;
    }

    const errorCode = pickGraphqlCode(queryError);
    if (errorCode) {
      setErrorMessage(campaignErrorMessage[errorCode] ?? errorCode);
    }
  }, [isNotFoundCampaign, queryError, onNotFound]);

  useEffect(() => {
    if (errorMessage) failure(errorMessage);
  }, [errorMessage, failure]);

  useEffect(() => {
    if (!campaign?.id || !initAchievementCode) return;

    if (!hasCustomer) {
      onSignIn();
      return;
    }

    recordMissionAchievementByAchievementCodeAndOpenModal(
      campaign.id,
      initAchievementCode,
    ).then(({ error }) => {
      const errorCode = pickGraphqlCode(error);
      if (
        errorCode &&
        // NOTE: ページリロードされることを想定して、すでに達成済みの場合はエラーとしない
        errorCode !== CampaignErrorType.ALREADY_CAMPAIGN_MISSION_ACHIEVED
      ) {
        setErrorMessage(campaignErrorMessage[errorCode] ?? errorCode);
      }
    });
  }, [
    campaign?.id,
    hasCustomer,
    initAchievementCode,
    onSignIn,
    recordMissionAchievementByAchievementCodeAndOpenModal,
  ]);

  const isCloudear =
    applicationId === process.env.NEXT_PUBLIC_APPLICATION_ID_CLOUDEAR ?? '';

  if (!campaign) {
    return null;
  }

  const contentViews = [
    {
      tabName: isCloudear ? 'シリアルコード' : 'ミッション',
      renderer: (
        <div className="divide-y sm:px-8">
          <div>
            <div className="flex flex-col items-center justify-start mb-12 gap-y-4">
              {missions?.map((mission) => {
                return (
                  <MissionItem
                    key={mission.id}
                    id={mission.id}
                    isRevealed={mission.isRevealed}
                    isExpiredCampaign={isExpiredCampaign}
                    startAt={mission.startAt}
                    endAt={mission.endAt}
                    title={mission.title}
                    pointAmount={mission.achievementPoint}
                    pointUnit={campaign.achievementPointName}
                    isAchieved={mission.achievedAt !== undefined}
                    onAction={onMissionStart}
                  />
                );
              })}
            </div>
            {hasAchievementIcons ? (
              <ButtonArea
                className="mb-12"
                description={`ミッションで手に入れた${campaign.achievementPointName}に応じて自動で${campaign.achievementIconName}がもらえます`}
              >
                <Button
                  className="max-w-[150px]"
                  onClick={() => setTabIndex(CampaignTabEnum.badge)}
                >
                  {`${campaign.achievementIconName}を見る`}
                </Button>
              </ButtonArea>
            ) : null}
          </div>

          <div className="pt-8 w-full">
            <Typography variant="h2" className="mb-4 text-gray-500">
              特典
            </Typography>

            <Typography
              markdown
              variant="body"
              className="mb-4 text-wallet-secondary"
            >
              {campaign.rewardDescription}
            </Typography>

            <Image
              className="min-h-[300px] mb-6 text-center"
              width={600}
              height={600}
              objectFit="contain"
              src={campaign.rewardImageAsset?.url}
              alt="campaign_profile_image"
            />
          </div>
        </div>
      ),
    },
    hasAchievementIcons
      ? {
          tabName: campaign.achievementIconName,
          renderer: (
            <div className="divide-y sm:px-8">
              <div>
                <div className="grid grid-flow-row grid-cols-1 gap-4 pb-12 sm:grid-cols-2">
                  {achievementIcons?.map((icon) => (
                    <BadgeCard
                      key={icon.id}
                      id={icon.id}
                      title={icon.title}
                      description={icon.description}
                      image={icon.iconNormalImageAsset?.url}
                      pointAmount={icon.achievementPoint ?? 0}
                      pointUnit={campaign.achievementPointName}
                      achievedAt={icon.achievedAt}
                    />
                  ))}
                </div>
                <ButtonArea
                  description={`ミッションで手に入れた${campaign.achievementPointName}に応じて自動で${campaign.achievementIconName}がもらえます`}
                >
                  <Button
                    className="max-w-[240px]"
                    onClick={() => setTabIndex(CampaignTabEnum.mission)}
                  >
                    ミッションを見る
                  </Button>
                </ButtonArea>
              </div>
            </div>
          ),
        }
      : undefined,
    hasExchangeableItems
      ? {
          tabName: '交換',
          renderer: (
            <div className="divide-y sm:px-8">
              <div className="flex flex-col space-y-4">
                {exchangeableItems && exchangeableItems.length > 0 ? (
                  <div className="grid grid-flow-row grid-cols-1 gap-4 sm:grid-cols-2">
                    {exchangeableItems.map((item) => {
                      return (
                        <ExchangeItemCard
                          key={item.id}
                          title={item.title}
                          pointAmount={item.achievementPoint ?? 0}
                          pointUnit={campaign.achievementPointName}
                          image={item.bannerImageAsset?.url}
                          startAt={item.startAt}
                          endAt={item.endAt}
                          onAction={onExchangeAction(item)}
                        />
                      );
                    })}
                  </div>
                ) : (
                  hasCustomer && (
                    <div className="flex flex-col items-center justify-center space-y-2">
                      <CompleteIcon className="w-16 h-16" />
                      <Typography variant="h2" className="text-center" markdown>
                        {'交換可能なアイテムは</br>全て交換しました'}
                      </Typography>
                    </div>
                  )
                )}
                <ButtonArea>
                  <Button
                    className="max-w-[240px]"
                    onClick={() => setTabIndex(CampaignTabEnum.exchanged)}
                  >
                    獲得済みアイテムをみる
                  </Button>
                </ButtonArea>
              </div>
            </div>
          ),
        }
      : undefined,
    hasExchangeableItems && hasCustomer
      ? {
          tabName: '獲得済',
          renderer: (
            <div className="divide-y sm:px-8">
              <div className="flex flex-col space-y-4">
                {exchangedItems && exchangedItems.length > 0 ? (
                  <div className="grid grid-flow-row grid-cols-1 gap-4 sm:grid-cols-2">
                    {exchangedItems?.map((item) => {
                      return (
                        <ExchangeItemCard
                          key={item.id}
                          title={item.title}
                          pointAmount={item.achievementPoint ?? 0}
                          pointUnit={campaign.achievementPointName}
                          startAt={item.startAt}
                          endAt={item.endAt}
                          exchangedAt={item.exchangedAtByCurrentCustomer}
                          image={item.bannerImageAsset?.url}
                          onAction={onExchangeAction(item)}
                        />
                      );
                    })}
                  </div>
                ) : (
                  hasCustomer && (
                    <div className="flex flex-col items-center justify-center space-y-2">
                      <Typography variant="h2" className="text-center" markdown>
                        {'獲得済みアイテムは</br>まだありません'}
                      </Typography>
                    </div>
                  )
                )}
                {exchangedItems && exchangedItems.length > 0 ? (
                  <ButtonArea description="獲得済アイテムはコレクションで確認できます">
                    <Button className="max-w-[240px]" onClick={onCollection}>
                      マイコレクションへ行く
                    </Button>
                  </ButtonArea>
                ) : (
                  <ButtonArea description="ポイントを集めてアイテムと交換しよう">
                    <Button
                      className="max-w-[240px]"
                      onClick={() => setTabIndex(CampaignTabEnum.exchangeable)}
                    >
                      交換可能アイテムをみる
                    </Button>
                  </ButtonArea>
                )}
              </div>
            </div>
          ),
        }
      : undefined,
  ];

  return (
    <>
      <div
        className="absolute top-0 left-0 w-full h-full z-base"
        style={{
          backgroundImage: `url(${campaign.backgroundImageAsset?.url})`,
        }}
      />
      <Container
        className="z-element max-w-[792px] sm:min-h-[620px] pt-0 sm:pt-0 px-0 sm:px-0 bg-opacity-100 overflow-hidden rounded-3xl dark:bg-wallet-base"
        containerClassName="px-4 py-8 sm:py-24"
        backgroundShadow
      >
        <Image
          className="w-full h-[160px] sm:h-[198px]"
          objectFit="cover"
          layout="fill"
          src={campaign.bannerImageAsset?.url}
          alt="campaign_banner_image"
        />

        <div className="flex flex-col px-4 py-8 sm:px-8 sm:py-10">
          <div className="relative">
            <Image
              className="w-[80px] sm:w-[138px] h-[80px] sm:h-[138px] absolute top-[-80px] sm:top-[-138px] left-[0px] sm:left-[0px]"
              objectFit="cover"
              layout="fill"
              imageClassName="rounded-full"
              src={campaign.profileImageAsset?.url}
              alt="campaign_profile_image"
            />
          </div>
          <div className="mb-4">
            <Typography
              variant="h1"
              className="mb-1 text-sm sm:text-[33px] leading-5 sm:leading-[42px]"
            >
              {campaign.title}
            </Typography>

            <Typography variant="body2" className="text-wallet-light-secondary">
              {format(campaign.startAt, 'M月D日')} 〜{' '}
              {format(campaign.endAt, 'M月D日')}
            </Typography>
          </div>
          <div className="mb-8">
            <Typography
              markdown
              variant="body"
              className="text-wallet-light-secondary"
            >
              {campaign.description}
            </Typography>
          </div>

          {customerPointBalance !== undefined &&
            customerExchangedPoint !== undefined && (
              <UserBadges
                achievementIconName={campaign.achievementIconName}
                achievementPointName={campaign.achievementPointName}
                customerPointBalance={customerPointBalance}
                customerExchangedPoint={customerExchangedPoint}
                achievementIcons={achievementIcons}
                applicationId={applicationId}
              />
            )}
        </div>
        <TabNavigator
          selectedIndex={tabIndex}
          onChangeIndex={setTabIndex}
          views={contentViews}
        />
        {!hasQuestLandingPageBy(slug) && (
          <div className="flex items-center justify-center p-4">
            <Accordion
              initOpen={true}
              className="max-w-full m-0 sm:m-8"
              title="キャンペーン注意事項"
            >
              {isCloudear ? (
                <Typography
                  variant="body2"
                  markdown
                  className="pt-4 text-wallet-light-primary dark:text-wallet-primary"
                >
                  キャンペーン細則が入ります
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  markdown
                  className="pt-4 text-wallet-light-primary dark:text-wallet-primary"
                >
                  {campaignNotice}
                </Typography>
              )}
            </Accordion>
          </div>
        )}
      </Container>

      <MissionAchievementModal
        show={modalState?.type === ModalStateType.MISSION_ACHIEVED}
        pointUnit={campaign.achievementPointName}
        mission={
          modalState?.type === ModalStateType.MISSION_ACHIEVED
            ? (modalState as MissionAchievedModalState).props.mission
            : undefined
        }
        onClose={closeModal}
      />

      <BadgeAwardModal
        show={modalState?.type === ModalStateType.BADGE_AWARD}
        pointUnit={campaign.achievementPointName}
        achievementIconName={campaign.achievementIconName}
        achievementIcon={
          modalState?.type === ModalStateType.BADGE_AWARD
            ? (modalState as BadgeAwardModalState)?.props?.achievementIcon
            : undefined
        }
        onClose={closeModal}
      />

      <ExchangeItemModal
        applicationId={applicationId}
        show={modalState?.type === ModalStateType.EXCHANGE_ITEM}
        pointUnit={campaign.achievementPointName}
        customerPointBalance={customerPointBalance}
        customerExchangedPoint={customerExchangedPoint}
        exchangeItem={
          modalState?.type === ModalStateType.EXCHANGE_ITEM
            ? (modalState as ExchangeItemModalState)?.props?.exchangeItem
            : undefined
        }
        onExchange={onExchange}
        onClose={closeModal}
      />

      <ExchangePointToItemModal
        show={modalState?.type === ModalStateType.EXCHANGE_POINT_TO_ITEM}
        exchangeItem={
          modalState?.type === ModalStateType.EXCHANGE_POINT_TO_ITEM
            ? (modalState as ExchangePointToItemModalState)?.props?.exchangeItem
            : undefined
        }
        dropItem={
          modalState?.type === ModalStateType.EXCHANGE_POINT_TO_ITEM
            ? (modalState as ExchangePointToItemModalState)?.props?.dropItem
            : undefined
        }
        onCollection={onCollection}
        onClose={onExchangeComplete}
      />

      <AchievePointByCodeModal
        applicationId={applicationId}
        show={modalState?.type === ModalStateType.ACHIEVE_POINT_BY_CODE}
        pointUnit={campaign.achievementPointName}
        customerPointBalance={customerPointBalance}
        customerExchangedPoint={customerExchangedPoint}
        missionItem={
          modalState?.type === ModalStateType.ACHIEVE_POINT_BY_CODE
            ? (modalState as AchievePointByCodeState)?.props?.missionItem
            : undefined
        }
        onAchieveMission={onAchieveCodeMission}
        onClose={closeModal}
      />

      <LoginModal
        show={modalState?.type === ModalStateType.LOGIN}
        onClose={closeModal}
        onSignIn={onSignIn}
      />

      {(modalState?.type === 'quiz-challenge' ||
        modalState?.type === 'quiz') && (
        <QuizModals
          modalState={modalState}
          onAchieveMission={onAchieveQuizMission}
          onCloseChallengeModal={closeModal}
          onCloseQuizModal={closeModal}
          onOpenQuizModal={onOpenQuizModal(campaign.achievementPointName)}
        />
      )}
    </>
  );
};
