import {
  MissionData,
  pickGraphqlError,
  useAchieveCampaignMissionPointByCodeMutation,
} from '@collection-platform-frontend/api';
import {
  Button,
  Image,
  Input,
  Typography,
} from '@collection-platform-frontend/ui';
import { FC, useCallback, useEffect, useState } from 'react';

import { PointColumn } from '../point-column';
import BaseModal from './base';

type Props = {
  show: boolean;
  applicationId: string;
  pointUnit: string;
  customerPointBalance?: number;
  customerExchangedPoint?: number;
  missionItem?: MissionData;
  onAchieveMission: (missionId: string, point: number) => void;
  onClose: () => void;
};

export const AchievePointByCodeModal: FC<Props> = ({
  show,
  applicationId,
  pointUnit,
  missionItem,
  onAchieveMission,
  onClose,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [code, setCode] = useState<string>('');

  const [{ fetching }, achievePointByCodeMutation] =
    useAchieveCampaignMissionPointByCodeMutation();

  useEffect(() => {
    if (!show) {
      setErrorMessage(undefined);
      setCode('');
    }
  }, [show]);

  const onExchangeCode = useCallback(async () => {
    if (!missionItem) {
      return;
    }

    const { error, data } = await achievePointByCodeMutation({
      applicationId,
      campaignMissionId: missionItem.id,
      code: code,
    });

    if (data?.achieveCampaignMissionByCode?.id) {
      onClose();
      setCode('');
      onAchieveMission(
        missionItem.id,
        data?.achieveCampaignMissionByCode?.achievementPoint || 0,
      );
    }

    const graphqlError = pickGraphqlError(error);
    setErrorMessage(graphqlError?.message);
  }, [
    missionItem,
    achievePointByCodeMutation,
    applicationId,
    code,
    onClose,
    onAchieveMission,
  ]);

  const onChangeCode = (value: string) => {
    setErrorMessage('');
    setCode(value);
  };

  if (!missionItem) {
    return null;
  }

  return (
    <BaseModal show={show} onClose={onClose}>
      <div className="mb-6">
        <Typography variant="h2" className="mb-2">
          ポイントと交換しますか？
        </Typography>
        <Typography variant="body2" className="text-wallet-light-secondary">
          交換可能なポイントを消費してアイテムを交換できます。
        </Typography>
      </div>
      <div className="flex flex-col items-center mb-6 space-y-2">
        {1 && (
          <Image
            className="h-[254px] w-[254px] rounded-3xl overflow-hidden"
            objectFit="cover"
            layout="fill"
            src={
              'https://cldr.jp/_next/image?url=https%3A%2F%2Fstorage.googleapis.com%2Fcollection-platform-api-prod-assets%2Fuploads%2Fbecea0cc-c5e2-4d0a-b815-10b0bcb693b5.png&w=3840&q=75'
            }
            alt="exchange_item_image"
          />
        )}
        <Typography variant="h3">{missionItem?.title}</Typography>
        <div className="flex flex-row items-center justify-center gap-x-1">
          <Typography variant="caption" className="text-wallet-light-secondary">
            必要ポイント：
          </Typography>
          <PointColumn
            amount={missionItem?.achievementPoint}
            unit={pointUnit}
          />
        </div>
      </div>
      <div className="w-full px-2 py-2 mb-6 rounded-3xl dark:bg-wallet-inactive">
        <Input
          minLength={16}
          maxLength={16}
          required
          value={code}
          forceUppercase={true}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          errorMessage={errorMessage}
          onChangeValue={onChangeCode}
        />
      </div>
      <div className="flex items-center flex-col mb-4 space-y-1">
        <Button
          primary
          className="w-[200px]"
          disabled={!code?.length || code?.length !== 16}
          loading={fetching}
          onClick={onExchangeCode}
        >
          交換する
        </Button>
        <div className="relative w-full">
          <span id="rewardLeft" className="absolute bottom-0 left-0" />
          <span id="rewardRight" className="absolute bottom-0 right-0" />
        </div>
      </div>
    </BaseModal>
  );
};
