import {
  AchievementIconData,
  DropItemData,
  ExchangeItemData,
  MissionData,
} from '@collection-platform-frontend/api';
import { useCallback, useEffect, useState } from 'react';

export enum ModalStateType {
  MISSION_ACHIEVED = 'mission-achievement',
  BADGE_AWARD = 'badge-award',
  EXCHANGE_ITEM = 'exchange-item',
  EXCHANGE_POINT_TO_ITEM = 'exchange-point-to-item',
  ACHIEVEMENT_CODE_FORM = 'achievement-code-form',
  LOGIN = 'login',
  ACHIEVE_POINT_BY_CODE = 'achieve-point-by-code',
}

export type MissionAchievedModalState = {
  type: ModalStateType.MISSION_ACHIEVED;
  props: {
    mission: {
      title?: string | null;
      pointAmount?: number | null;
    };
  };
};

export type BadgeAwardModalState = {
  type: ModalStateType.BADGE_AWARD;
  props: {
    achievementIcon: {
      id: string;
      title: string;
      description: string;
      image?: string;
      pointAmount: number;
      achievedAt?: Date;
    };
  };
};

export type ExchangeItemModalState = {
  type: ModalStateType.EXCHANGE_ITEM;
  props: {
    exchangeItem: ExchangeItemData;
  };
};

export type ExchangePointToItemModalState = {
  type: ModalStateType.EXCHANGE_POINT_TO_ITEM;
  props: {
    exchangeItem: ExchangeItemData;
    dropItem: DropItemData;
  };
};

export type MissionCodeInputModalState = {
  type: ModalStateType.ACHIEVEMENT_CODE_FORM;
};

export type LoginModalState = {
  type: ModalStateType.LOGIN;
};

export type QuizChallengeModalState = {
  type: 'quiz-challenge';
  props: {
    mission: {
      id: string | undefined;
    };
  };
};
type QuizModalChallengeStateProps = QuizChallengeModalState['props'];

export type QuizModalState = {
  type: 'quiz';
  props: {
    mission: {
      id: string;
    };
    achievementPointName: string;
    quizId: string;
  };
};

type QuizModalStateProps = QuizModalState['props'];

export type AchievePointByCodeState = {
  type: ModalStateType.ACHIEVE_POINT_BY_CODE;
  props: {
    missionItem: MissionData;
  };
};

export type ModalState =
  | undefined
  | MissionAchievedModalState
  | BadgeAwardModalState
  | MissionCodeInputModalState
  | LoginModalState
  | QuizChallengeModalState
  | QuizModalState
  | ExchangeItemModalState
  | ExchangePointToItemModalState
  | LoginModalState
  | AchievePointByCodeState;

function useQueue<T>() {
  const [queue, setQueue] = useState<T[]>([]);

  const enqueue = useCallback((state: T) => {
    setQueue((queue) => [...queue, state]);
  }, []);

  const dequeue = useCallback(() => {
    setQueue((queue) => {
      const _queue = [...queue];
      _queue.shift();

      return _queue;
    });
  }, []);

  return {
    queue,
    enqueue,
    dequeue,
  };
}

export const useModal = (isLoggedIn: boolean) => {
  const { queue, enqueue, dequeue } = useQueue<ModalState>();
  const [state, setState] = useState<ModalState>();
  const firstQueue = queue[0];

  const openLoginModal = useCallback(() => {
    enqueue({
      type: ModalStateType.LOGIN,
    });
  }, [enqueue]);

  const openAchievementCodeFormModal = useCallback(() => {
    if (!isLoggedIn) {
      openLoginModal();
      return;
    }

    enqueue({
      type: ModalStateType.ACHIEVEMENT_CODE_FORM,
    });
  }, [isLoggedIn, enqueue, openLoginModal]);

  const openMissionAchievementModal = useCallback(
    (mission: MissionData) => {
      enqueue({
        type: ModalStateType.MISSION_ACHIEVED,
        props: {
          mission: {
            title: mission.title,
            pointAmount: mission.achievementPoint,
          },
        },
      });
    },
    [enqueue],
  );

  const openExchangeItemModal = useCallback(
    (item: ExchangeItemData) => {
      enqueue({
        type: ModalStateType.EXCHANGE_ITEM,
        props: {
          exchangeItem: item,
        },
      });
    },
    [enqueue],
  );

  const openExchangePointToItemModal = useCallback(
    (item: ExchangeItemData, dropItem: DropItemData) => {
      enqueue({
        type: ModalStateType.EXCHANGE_POINT_TO_ITEM,
        props: {
          exchangeItem: item,
          dropItem,
        },
      });
    },
    [enqueue],
  );

  const openBadgeAwardModal = useCallback(
    (icon: AchievementIconData) => {
      enqueue({
        type: ModalStateType.BADGE_AWARD,
        props: {
          achievementIcon: {
            id: icon.id,
            title: icon.title,
            description: icon.title,
            image: icon.iconNormalImageAsset?.url,
            pointAmount: icon.achievementPoint,
            achievedAt: icon.achievedAt,
          },
        },
      });
    },
    [enqueue],
  );

  const openQuizChallengeModal = useCallback(
    (props: QuizModalChallengeStateProps) => {
      enqueue({
        type: 'quiz-challenge',
        props: props,
      });
    },
    [enqueue],
  );

  const openQuizModal = useCallback(
    (props: QuizModalStateProps) => {
      enqueue({
        type: 'quiz',
        props: props,
      });
    },
    [enqueue],
  );

  const openAchievePointByCodeModal = useCallback(
    (item: MissionData) => {
      enqueue({
        type: ModalStateType.ACHIEVE_POINT_BY_CODE,
        props: {
          missionItem: item,
        },
      });
    },
    [enqueue],
  );

  useEffect(() => {
    setState(firstQueue);
  }, [firstQueue]);

  return {
    state,
    openModal: enqueue,
    closeModal: dequeue,
    openMissionAchievementModal,
    openBadgeAwardModal,
    openAchievementCodeFormModal,
    openExchangeItemModal,
    openExchangePointToItemModal,
    openLoginModal,
    openQuizChallengeModal,
    openQuizModal,
    openAchievePointByCodeModal,
  };
};
